import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import { ClusterIcon } from "./ClusterIcon/index.js";
import { Popup } from "./Popup/index.js";
import * as styles from "./index.module.css";
import MarkerIcon from "./marker-icon.png";

const defaultIcon = L.icon({
  iconUrl: MarkerIcon,
  iconSize: [50, 50],
  iconAnchor: [25, 50],
});

const userIcon = L.divIcon({
  className: "user-marker",
  html: '<div style="width: 14px; height: 14px; background: #80C684; border: 3px solid white; border-radius: 50%;"></div>',
  iconSize: [20, 20],
  iconAnchor: [10, 10],
});

const Map = ({ className, stationsData }) => {
  const { lat, lon } = useSelector((state) => state.location);
  const mapRef = useRef(null);
  const markerClusterGroupRef = useRef(null);

  useEffect(() => {
    const center = lat && lon ? [lat, lon] : [48.143857, 17.107577];

    mapRef.current = L.map("map").setView(center, 15);
    L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
      attribution: "&copy; OpenStreetMap contributors",
    }).addTo(mapRef.current);

    markerClusterGroupRef.current = L.markerClusterGroup({
      iconCreateFunction: function (cluster) {
        const markers = cluster.getAllChildMarkers();
        const html = ReactDOMServer.renderToString(<ClusterIcon count={markers.length} />);
        return L.divIcon({ html, className: "mycluster", iconSize: L.point(32, 32) });
      },
    });

    mapRef.current.addLayer(markerClusterGroupRef.current);

    if (stationsData) {
      addMarkers(stationsData);
    }

    if (lat && lon) {
      addUserMarker([lat, lon]);
    }

    return () => {
      mapRef.current.remove();
    };
  }, [lat, lon]);

  const addUserMarker = (userCenter) => {
    L.marker(userCenter, { icon: userIcon, title: "Вы здесь" })
      .addTo(mapRef.current)
  };

  const addMarkers = (stationsData) => {
    if (markerClusterGroupRef.current) {
      markerClusterGroupRef.current.clearLayers();
    }

    stationsData.forEach((stationData) => {
      const marker = L.marker([stationData.station.lat, stationData.station.lon], { icon: defaultIcon })
        .bindPopup(
          ReactDOMServer.renderToString(
            <Popup title={stationData.station.name} description={stationData.station.address} />
          )
        );
      markerClusterGroupRef.current.addLayer(marker);
    });

    mapRef.current.addLayer(markerClusterGroupRef.current);
  };

  return <div className={className} id="map"></div>;
};

export { Map };
