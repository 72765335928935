import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'shared/api/baseQuery';

export const stationsApi = createApi({
  reducerPath: 'stationsApi',
  baseQuery,
  endpoints: (builder) => ({
    getStations: builder.query({
      query: () => 'stations/',
    }),
    getNearestStations: builder.query({
      query: ({ lat, lon, limit = 3 }) => ({
        url: "nearest-stations/",
        method: "POST",
        body: { lat, lon, limit },
      }),
    }),
  }),
});

export const { useGetStationsQuery, useGetNearestStationsQuery } = stationsApi;
export default stationsApi;
