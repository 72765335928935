// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__title__iNzTt {
  font-size: 24px;
  line-height: 100%;
}

@media (min-width: 768px) {
  .index-module__title__iNzTt {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  .index-module__title__iNzTt {
    font-size: 36px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/Title/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".title {\n  font-size: 24px;\n  line-height: 100%;\n}\n\n@media (min-width: 768px) {\n  .title {\n    font-size: 28px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .title {\n    font-size: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `index-module__title__iNzTt`;
export default ___CSS_LOADER_EXPORT___;
