import React from 'react';
import { NearestStations } from "./NearestStations/index.js"
import { HomeNews } from "./News/index.js"
import { HomeTips } from "./Tips/index.js"
import { Title } from "shared/ui/components/Title/index.js"
import { Container } from "shared/ui/components/Container/index.js"
import * as styles from "./index.module.css"
import newsImg from "./news-item.png"
import tipImg from "./tip.png"

const newsData = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur",
    imgSrc: newsImg
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur. ds FJfdk Jdfkjk jdfjfj jd jdfjdfjjf",
    imgSrc: newsImg
  }
];

const tipsData = [
  {
    title: "Ako môžem začať prenájom povobanky?",
    imgSrc: tipImg
  },
  {
    title: "Ako môžem začať prenájom povobanky?",
    imgSrc: tipImg
  },
  {
    title: "Ako môžem začať prenájom povobanky?",
    imgSrc: tipImg
  },
  {
    title: "Ako môžem začať prenájom povobanky?",
    imgSrc: tipImg
  }
];

const Home = ({ className }) => {
  return (
    <div className={`${className}`}>
      <Container>
        <Title text="Najbližšie stanice" className={styles.home__title}></Title>
        <NearestStations className={styles.homeBlock} />

        <Title text="Noviny" className={styles.home__title}></Title>
        <HomeNews className={styles.homeBlock} news={newsData} />

        <Title text="Nápovedy" className={styles.home__title}></Title>
        <HomeTips className={styles.homeBlock} tips={tipsData} />
      </Container>
    </div>
  )
}

export { Home }
