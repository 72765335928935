import React, { Component } from "react";
import * as styles from "./index.module.css"

export const Popup = ({ title, description }) => {
  return (
    <div className={styles.popup}>
      <h3 className={styles.popup_title}>{title}</h3>
      <p className={styles.popup_description}>{description}</p>
    </div>
  );
};
