// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__navbar__J7DeB {
  background-color: var(--primary-color);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.index-module__navbar__J7DeB .index-module__container__vGicd {
  padding: 0;
}

.index-module__navbar__list__PUxdt {
  display: flex;
}

.index-module__navbar__listItem__g_5cJ {
  flex: 1;
  text-align: center;
}

.index-module__navbar__button__NoTYL {
  width: 100%;
  height: 60px;
  transition: background-color 0.2s ease-in-out;
}

.index-module__navbar__button_active__bNv3V {
  background-color: var(--accent-color);
}

.index-module__navbar__icon__BRtXH {
  height: 45px;
}

@media (min-width: 768px) {
  .index-module__navbar__J7DeB {
    top: 0;
    bottom: 0;
    width: 80px;
  }

  .index-module__navbar__list__PUxdt {
    flex-direction: column;
  }

  .index-module__navbar__J7DeB .index-module__container__vGicd,
  .index-module__navbar__inner__EPqK5,
  .index-module__navbar__menu__vfbao,
  .index-module__navbar__list__PUxdt,
  .index-module__navbar__button__NoTYL {
    height: 100%;
  }

  .index-module__navbar__icon__BRtXH {
    height: 50px;
  }
}

@media (min-width: 1024px) {
  .index-module__navbar__J7DeB {
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    bottom: auto;
  }

  .index-module__navbar__list__PUxdt {
    flex-direction: row;
  }

  .index-module__navbar__icon__BRtXH {
    height: 60px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/widgets/Navbar/index.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6CAA6C;AAC/C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,MAAM;IACN,SAAS;IACT,WAAW;EACb;;EAEA;IACE,sBAAsB;EACxB;;EAEA;;;;;IAKE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".navbar {\n  background-color: var(--primary-color);\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 1000;\n}\n\n.navbar .container {\n  padding: 0;\n}\n\n.navbar__list {\n  display: flex;\n}\n\n.navbar__listItem {\n  flex: 1;\n  text-align: center;\n}\n\n.navbar__button {\n  width: 100%;\n  height: 60px;\n  transition: background-color 0.2s ease-in-out;\n}\n\n.navbar__button_active {\n  background-color: var(--accent-color);\n}\n\n.navbar__icon {\n  height: 45px;\n}\n\n@media (min-width: 768px) {\n  .navbar {\n    top: 0;\n    bottom: 0;\n    width: 80px;\n  }\n\n  .navbar__list {\n    flex-direction: column;\n  }\n\n  .navbar .container,\n  .navbar__inner,\n  .navbar__menu,\n  .navbar__list,\n  .navbar__button {\n    height: 100%;\n  }\n\n  .navbar__icon {\n    height: 50px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .navbar {\n    width: 100%;\n    height: 80px;\n    top: 0;\n    left: 0;\n    bottom: auto;\n  }\n\n  .navbar__list {\n    flex-direction: row;\n  }\n\n  .navbar__icon {\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var navbar = `index-module__navbar__J7DeB`;
export var container = `index-module__container__vGicd`;
export var navbar__list = `index-module__navbar__list__PUxdt`;
export var navbar__listItem = `index-module__navbar__listItem__g_5cJ`;
export var navbar__button = `index-module__navbar__button__NoTYL`;
export var navbar__button_active = `index-module__navbar__button_active__bNv3V`;
export var navbar__icon = `index-module__navbar__icon__BRtXH`;
export var navbar__inner = `index-module__navbar__inner__EPqK5`;
export var navbar__menu = `index-module__navbar__menu__vfbao`;
export default ___CSS_LOADER_EXPORT___;
