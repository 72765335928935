// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__newsItem__PxGSk {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  width: 100%;
}

.index-module__newsItem__title__JXVZX {
  color: white;
  margin: auto 0;
  padding: 0 20px;
  font-size: 20px;
  flex: 1;
}

.index-module__newsItem__imageContainer__341Zu {
  min-height: 150px;
  height: 100%;
  flex: 1;
  border-radius: 0 16px 16px 0;
  overflow: hidden;
}

.index-module__newsItem__imageContainer__341Zu img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .index-module__newsItem__title__JXVZX {
    padding: 20px;
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .index-module__newsItem__title__JXVZX {
    padding: 40px;
    font-size: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/News/index.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,OAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,OAAO;EACP,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".newsItem {\n  background-color: var(--primary-color);\n  display: flex;\n  justify-content: space-between;\n  border-radius: 16px;\n  width: 100%;\n}\n\n.newsItem__title {\n  color: white;\n  margin: auto 0;\n  padding: 0 20px;\n  font-size: 20px;\n  flex: 1;\n}\n\n.newsItem__imageContainer {\n  min-height: 150px;\n  height: 100%;\n  flex: 1;\n  border-radius: 0 16px 16px 0;\n  overflow: hidden;\n}\n\n.newsItem__imageContainer img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n@media (min-width: 768px) {\n  .newsItem__title {\n    padding: 20px;\n    font-size: 24px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .newsItem__title {\n    padding: 40px;\n    font-size: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var newsItem = `index-module__newsItem__PxGSk`;
export var newsItem__title = `index-module__newsItem__title__JXVZX`;
export var newsItem__imageContainer = `index-module__newsItem__imageContainer__341Zu`;
export default ___CSS_LOADER_EXPORT___;
