// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__station__ASjPt {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  padding: 20px 30px;
  border-radius: 16px;
  flex: 1;
}

.index-module__station__title__fS81Z {
  font-size: 20px;
  margin-bottom: 6px;
}

.index-module__station__description__i0Y54 {
  margin-bottom: 6px;
}

.index-module__station__details__AECGu {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.index-module__station__time__QJKeG.index-module__open__iesEs {
  color: var(--accent-color)
}

.index-module__station__time__QJKeG.index-module__soon__xzJ7u {
  color: #f4d35e
}

.index-module__station__time__QJKeG.index-module__closed__eh17I {
  color: #e76f51
}

@media (min-width: 768px) {
  .index-module__station__title__fS81Z {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .index-module__station__title__fS81Z {
    margin-bottom: 10px;
  }

  .index-module__station__description__i0Y54 {
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/NearestStation/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,OAAO;AACT;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".station {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  background-color: white;\n  padding: 20px 30px;\n  border-radius: 16px;\n  flex: 1;\n}\n\n.station__title {\n  font-size: 20px;\n  margin-bottom: 6px;\n}\n\n.station__description {\n  margin-bottom: 6px;\n}\n\n.station__details {\n  margin-top: auto;\n  display: flex;\n  justify-content: space-between;\n}\n\n.station__time.open {\n  color: var(--accent-color)\n}\n\n.station__time.soon {\n  color: #f4d35e\n}\n\n.station__time.closed {\n  color: #e76f51\n}\n\n@media (min-width: 768px) {\n  .station__title {\n    font-size: 24px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .station__title {\n    margin-bottom: 10px;\n  }\n\n  .station__description {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var station = `index-module__station__ASjPt`;
export var station__title = `index-module__station__title__fS81Z`;
export var station__description = `index-module__station__description__i0Y54`;
export var station__details = `index-module__station__details__AECGu`;
export var station__time = `index-module__station__time__QJKeG`;
export var open = `index-module__open__iesEs`;
export var soon = `index-module__soon__xzJ7u`;
export var closed = `index-module__closed__eh17I`;
export default ___CSS_LOADER_EXPORT___;
