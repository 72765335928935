// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__filterButton__CGqUD {
  padding: 10px;
  font-size: 16px;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.index-module__filterButton__CGqUD.index-module__active__ZzdcI {
  background-color: var(--primary-color);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/geo/FilterButton/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,sCAAsC;EACtC,mBAAmB;EACnB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;EACtC,YAAY;AACd","sourcesContent":[".filterButton {\n  padding: 10px;\n  font-size: 16px;\n  color: var(--primary-color);\n  border: 2px solid var(--primary-color);\n  border-radius: 22px;\n  cursor: pointer;\n  transition: background 0.3s ease;\n}\n\n.filterButton.active {\n  background-color: var(--primary-color);\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export var filterButton = `index-module__filterButton__CGqUD`;
export var active = `index-module__active__ZzdcI`;
export default ___CSS_LOADER_EXPORT___;
