import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container } from "shared/ui/components/Container/index.js";
import HomeIcon from "shared/ui/icons/home.svg";
import GeoIcon from "shared/ui/icons/geo.svg";
import BatteryIcon from "shared/ui/icons/battery.svg";
import avatar from "shared/ui/icons/avatar.png";
import * as styles from "./index.module.css";

function Navbar() {
  const location = useLocation(); // Получаем текущий путь

  const buttons = [
    { id: "home", src: HomeIcon, path: "/" },
    { id: "geo", src: GeoIcon, path: "/geo" },
    { id: "battery", src: BatteryIcon, path: "/battery" },
    { id: "avatar", src: avatar, path: "/avatar" },
  ];

  return (
    <header className={styles.navbar}>
      <Container className={styles.container}>
        <div className={styles.navbar__inner}>
          <nav className={styles.navbar__menu}>
            <ul className={styles.navbar__list}>
              {buttons.map((button) => (
                <li className={styles.navbar__listItem} key={button.id}>
                  <Link to={button.path}>
                    <button
                      className={`${styles.navbar__button} ${
                        location.pathname === button.path ? styles.navbar__button_active : ""
                      }`}
                    >
                      {typeof button.src === "string" ? (
                        <img className={styles.navbar__icon} src={button.src} alt={button.id} />
                      ) : (
                        <button.src className={styles.navbar__icon} />
                      )}
                    </button>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
}

export { Navbar };
