import React from "react";
import * as styles from "./index.module.css";

class FilterButton extends React.Component {
  state = {
    isActive: false,
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));

    if (this.props.onClick) {
      this.props.onClick(!this.state.isActive);
    }
  };

  render() {
    return (
      <button
        className={`${styles.filterButton} ${this.state.isActive ? styles.active : ""}`}
        onClick={this.toggleFilter}
      >
        {this.props.label || "Filter"}
      </button>
    );
  }
}

export { FilterButton };
