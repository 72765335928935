// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__clusterMarker__gqisM {
  background-color: var(--primary-color);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Map/ClusterIcon/index.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,2CAA2C;AAC7C","sourcesContent":[".clusterMarker {\n  background-color: var(--primary-color);\n  color: white;\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  text-align: center;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
export var clusterMarker = `index-module__clusterMarker__gqisM`;
export default ___CSS_LOADER_EXPORT___;
