// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__popup_title__kGEam {
  font-size: 18px;
  font-weight: bold;
}

.index-module__popup_description__y_hKv {
  margin: 0;
}

@media (min-width: 768) {
  .index-module__popup_title__kGEam {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Map/Popup/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".popup_title {\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.popup_description {\n  margin: 0;\n}\n\n@media (min-width: 768) {\n  .popup_title {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var popup_title = `index-module__popup_title__kGEam`;
export var popup_description = `index-module__popup_description__y_hKv`;
export default ___CSS_LOADER_EXPORT___;
