import React, { Component } from "react";
import * as styles from "./index.module.css"

export const ClusterIcon = ({ count }) => {
  let sizeClass = count < 10 ? "small" : count < 50 ? "medium" : "large";

  return (
    <div className={`${styles.clusterMarker} ${sizeClass}`}>
      <span>{count}</span>
    </div>
  );
};
