import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import * as styles from "./index.module.css";

export const HomeNews = ({ news, className }) => {
  return (
    <div className={`${className}`}>
      <Swiper
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          }
        }}
      >
        {news.map((newsItem, index) => (
          <SwiperSlide key={index}>
            <div className={styles.newsItem}>
              <h2 className={styles.newsItem__title}>
                {newsItem.title}
              </h2>
              <div className={styles.newsItem__imageContainer}>
                <img src={newsItem.imgSrc} alt="new photo" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
