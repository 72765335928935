import React, { useState, useEffect, useRef } from "react";
import { Map } from "shared/components/Map/index.js"
import { useSelector } from "react-redux";
import { NearestStation } from "shared/components/NearestStation/index.js"
import { FilterButton } from "./FilterButton/index.js"
import { BottomSheet } from "widgets/BottomSheet/index.js"
import { useGetNearestStationsQuery } from "entities/station/model/stationApi";

import * as styles from "./index.module.css"

export const Geo = ({ className }) => {
  const [open, setOpen] = useState(true);
  const sheetRef = useRef(null);
  const { lat, lon } = useSelector((state) => state.location);

  const { data: stationsData, isLoading, error } = useGetNearestStationsQuery({ lat, lon, limit: 3 });

  return (
    <div className={`${styles.geo} ${className}`}>
      {isLoading && (
        <Map className={styles.map} />
      )}
      {!isLoading && !error && (
        <Map className={styles.map} stationsData={stationsData} />
      )}
      <BottomSheet
        ref={sheetRef}
        className={styles.sheet}
        open={open}
      // header={
      //   <form className={styles.searchForm}>
      //     <input
      //       className={styles.searchInput}
      //       type="text"
      //       name="search"
      //       placeholder="Search.."
      //       onFocus={() => {
      //         if (sheetRef.current) {
      //           sheetRef.current.snapTo(({ maxHeight }) => maxHeight); // Открываем на максимум
      //         }
      //       }}
      //     />
      //   </form>
      // }
      >
        {isLoading && (
          <>
            <NearestStation />
            <NearestStation />
            <NearestStation />
          </>
        )}
        {!isLoading && !error && (
          <div className={styles.nearestStations}>
            {stationsData.map((stationData, index) => (
              <NearestStation key={index} stationData={stationData} />
            ))}
          </div>
        )}
      </BottomSheet>
    </div>
  );
};
