// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  :root {
    --rsbs-ml: 80px;
  }
}

@media (min-width: 1024px) {
  :root {
    --rsbs-ml: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/widgets/BottomSheet/index.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@media (min-width: 768px) {\n  :global(:root) {\n    --rsbs-ml: 80px;\n  }\n}\n\n@media (min-width: 1024px) {\n  :global(:root) {\n    --rsbs-ml: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
