// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-popup {
  margin-bottom: 0;
}

.leaflet-popup-content-wrapper {
}

.leaflet-popup-content-wrapper a {
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup-tip {
  /* border-left:15px solid transparent; */
  /* border-right:15px solid transparent; */
  /* border-top:15px solid #2c3e50; */
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Map/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;AACA;;AAEA;AACA;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wCAAwC;EACxC,yCAAyC;EACzC,mCAAmC;AACrC","sourcesContent":[":global(.leaflet-popup) {\n  margin-bottom: 0;\n}\n\n:global(.leaflet-popup-content-wrapper) {\n}\n\n:global(.leaflet-popup-content-wrapper a) {\n}\n\n:global(.leaflet-popup-tip-container) {\n  display: none;\n}\n\n:global(.leaflet-popup-tip) {\n  /* border-left:15px solid transparent; */\n  /* border-right:15px solid transparent; */\n  /* border-top:15px solid #2c3e50; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
