import React, { useEffect } from "react";
import StoreProvider from './providers/StoreProvider';
import { Routes, Route } from "react-router-dom";
import { Navbar } from "widgets/Navbar/index.js";
import { Home } from "pages/home/index.js";
import { Geo } from "pages/geo/index.js";
import { Orders } from "pages/orders/index.js";
import { ProfileSettings } from "pages/profile_settings/index.js";
import { useDispatch } from "react-redux";
import { fetchUserLocation } from "entities/location";
import "./styles/reset.css";
import "./styles/variables.css";
import * as styles from "./styles/style.module.css";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserLocation());
  }, [dispatch]);

  return (
    <div className="app">
      <Navbar />
      <main className={styles.main}>
        <Routes>
          <Route path="/" element={<Home className={styles.content}/>} />
          <Route path="/geo" element={<Geo className={styles.content}/>} />
          <Route path="/battery" element={<Orders className={styles.content}/>} />
          <Route path="/avatar" element={<ProfileSettings className={styles.content}/>} />
        </Routes>
      </main>
    </div>
  );
};

export { App };
