import React from 'react';

class Orders extends React.Component {
  render() {
    return (
      <div>
        Orders: in process..
      </div>
    )
  }
}

export { Orders }
