// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("shared/ui/icons/search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__geo__UzWsx {
}

.index-module__map__tR0mm {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.index-module__searchForm__ahNHN {
  margin-bottom: 15px;
}

.index-module__searchInput__7iYSQ {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 22px;
  font-size: 16px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 30px 30px;
  background-position: 12px 7px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 50px;
  background-color: #f3f3f3;
}

.index-module__nearestStations__Xm6Ix {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.index-module__nearestStations__Xm6Ix::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .index-module__geo__UzWsx {
    flex-direction: row-reverse;
  }

  .index-module__map__tR0mm {
    height: 100%;
    width: auto;
  }

  .index-module__stationsInfo__CGJsn {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .index-module__map__tR0mm {
    flex: 7;
  }

  .index-module__stationsInfo__CGJsn {
    flex: 3;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/geo/index.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,yDAAmD;EACnD,0BAA0B;EAC1B,6BAA6B;EAC7B,4BAA4B;EAC5B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,2BAA2B;EAC7B;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;AACF;;AAEA;EACE;IACE,OAAO;EACT;;EAEA;IACE,OAAO;EACT;AACF","sourcesContent":[".geo {\n}\n\n.map {\n  height: 100%;\n  width: 100%;\n  z-index: 0;\n}\n\n.searchForm {\n  margin-bottom: 15px;\n}\n\n.searchInput {\n  width: 100%;\n  box-sizing: border-box;\n  border: none;\n  border-radius: 22px;\n  font-size: 16px;\n  background-image: url('shared/ui/icons/search.svg');\n  background-size: 30px 30px;\n  background-position: 12px 7px;\n  background-repeat: no-repeat;\n  padding: 12px 20px 12px 50px;\n  background-color: #f3f3f3;\n}\n\n.nearestStations {\n  overflow-y: auto;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n\n.nearestStations::-webkit-scrollbar {\n  display: none;\n}\n\n@media (min-width: 768px) {\n  .geo {\n    flex-direction: row-reverse;\n  }\n\n  .map {\n    height: 100%;\n    width: auto;\n  }\n\n  .stationsInfo {\n    height: 100%;\n    width: auto;\n  }\n}\n\n@media (min-width: 1024px) {\n  .map {\n    flex: 7;\n  }\n\n  .stationsInfo {\n    flex: 3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var geo = `index-module__geo__UzWsx`;
export var map = `index-module__map__tR0mm`;
export var searchForm = `index-module__searchForm__ahNHN`;
export var searchInput = `index-module__searchInput__7iYSQ`;
export var nearestStations = `index-module__nearestStations__Xm6Ix`;
export var stationsInfo = `index-module__stationsInfo__CGJsn`;
export default ___CSS_LOADER_EXPORT___;
