// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__container__K5wMp {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .index-module__container__K5wMp {
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/Container/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,iCAAiC;IACjC,eAAe;IACf,cAAc;EAChB;AACF","sourcesContent":[".container {\n  padding: 0 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n@media (min-width: 1024px) {\n  .container {\n    max-width: var(--container-width);\n    padding: 0 15px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `index-module__container__K5wMp`;
export default ___CSS_LOADER_EXPORT___;
