import React from "react";
import { NearestStation } from "shared/components/NearestStation/index.js"
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from "react-redux";
import { useGetNearestStationsQuery } from "entities/station/model/stationApi";

import 'swiper/css';
import * as styles from "./index.module.css"

export const NearestStations = ({ className }) => {
  const { lat, lon } = useSelector((state) => state.location);

  const { data: stationsData, isLoading, error } = useGetNearestStationsQuery({ lat, lon, limit: 3 });

  return (
    <div className={`${className}`}>
      <Swiper
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {isLoading && (
          <>
            <SwiperSlide>
              <NearestStation />
            </SwiperSlide>
            <SwiperSlide>
              <NearestStation />
            </SwiperSlide>
            <SwiperSlide>
              <NearestStation />
            </SwiperSlide>
          </>
        )}
        {/* {error && <p>Ошибка загрузки станций</p>} */}
        {!isLoading && !error && (
          stationsData.map((stationData, index) => (
            <SwiperSlide key={index}>
              <NearestStation stationData={stationData} />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};
