// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__tips__list__KSbxA {
  display: flex;
  gap: 20px;
}

.index-module__tip__hseGu {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.index-module__tip__imageContainer__Rxc1G {
  height: 150px;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.index-module__tip__imageContainer__Rxc1G img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-module__tip__title__doZfh {
  font-size: 20px;
  padding: 20px 10px;
}

@media (min-width: 768px) {
  .index-module__tip__title__doZfh {
    padding: 20px 20px;
  }
}

@media (min-width: 1024px) {
  .index-module__tip__title__doZfh {
    font-size: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/Tips/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".tips__list {\n  display: flex;\n  gap: 20px;\n}\n\n.tip {\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  border-radius: 16px;\n}\n\n.tip__imageContainer {\n  height: 150px;\n  overflow: hidden;\n  border-radius: 16px 16px 0 0;\n}\n\n.tip__imageContainer img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.tip__title {\n  font-size: 20px;\n  padding: 20px 10px;\n}\n\n@media (min-width: 768px) {\n  .tip__title {\n    padding: 20px 20px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .tip__title {\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var tips__list = `index-module__tips__list__KSbxA`;
export var tip = `index-module__tip__hseGu`;
export var tip__imageContainer = `index-module__tip__imageContainer__Rxc1G`;
export var tip__title = `index-module__tip__title__doZfh`;
export default ___CSS_LOADER_EXPORT___;
