import React from "react";
import { Skeleton } from "shared/ui/components/Skeleton";
import * as styles from "./index.module.css"

const NearestStation = ({ stationData }) => {
  const getClosingStatus = (closeTime) => {
    if (!closeTime) return "open";

    const now = new Date();
    const [hours, minutes] = closeTime.split(":").map(Number);
    const closingTime = new Date(now);
    closingTime.setHours(hours, minutes, 0, 0);

    const timeDiff = (closingTime - now) / (1000 * 60);

    if (timeDiff <= 0) return "closed";
    if (timeDiff < 60) return "soon";
    return "open";
  };

  if (!stationData) {
    return <Skeleton height="100px" />
  }

  const closingStatus = getClosingStatus(stationData.station.close_time);

  return (
    <div className={styles.station}>
      <h2 className={styles.station__title}>{stationData.station.name}</h2>
      <p className={styles.station__description}>{stationData.station.address}</p>
      <div className={styles.station__details}>
        {stationData.distance != null && (
          <p className={styles.station__distance}>
            {stationData.distance > 1000
              ? `${Math.round(stationData.distance / 100) / 10} km`
              : `${stationData.distance} m`}
          </p>
        )}
        <p className={`${styles.station__time} ${styles[closingStatus]}`}>
          {stationData.station.close_time ? stationData.station.close_time.split(":").slice(0, 2).join(":") : "24/7"}
        </p>
      </div>
    </div>
  );
};

export { NearestStation }
