import React, { Component } from "react";
import { useState, useEffect, forwardRef } from "react";
import { BottomSheet as BottomSheetPlugin } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "./index.module.css";

export const BottomSheet = forwardRef(({ open, children, header }, ref) => {
  const [marginBottom, setMarginBottom] = useState(60);

  useEffect(() => {
    const updateMargin = () => {
      if (window.innerWidth >= 768) {
        setMarginBottom(0);
      } else {
        setMarginBottom(60);
      }
    };

    updateMargin();
    window.addEventListener("resize", updateMargin);

    return () => window.removeEventListener("resize", updateMargin);
  }, []);

  return (
    <BottomSheetPlugin
      open={open}
      ref={ref}
      blocking={false}
      snapPoints={({ headerHeight, maxHeight }) => [headerHeight + marginBottom, maxHeight * 0.8]}
      header={header}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.min(...snapPoints)
      }
    >
      {children}
    </BottomSheetPlugin>
  );
});
