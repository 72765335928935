import React from "react";
import * as styles from "./index.module.css";

export const Skeleton = ({ width = "100%", height = "150px", borderRadius = "8px" }) => {
  return (
    <div
      className={styles.skeletonBox}
      style={{ width, height, borderRadius }}
    ></div>
  );
};
