// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__home__title__hFwlj {
  margin-bottom: 15px;
}

.index-module__homeBlock__VAvS0 {
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".home__title {\n  margin-bottom: 15px;\n}\n\n.homeBlock {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export var home__title = `index-module__home__title__hFwlj`;
export var homeBlock = `index-module__homeBlock__VAvS0`;
export default ___CSS_LOADER_EXPORT___;
