import { configureStore } from '@reduxjs/toolkit';
import { stationsApi } from 'entities/station/model/stationApi';
import locationReducer from "entities/location/model/locationSlice";

export const store = configureStore({
  reducer: {
    [stationsApi.reducerPath]: stationsApi.reducer,
    location: locationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(stationsApi.middleware),
});
