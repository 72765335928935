import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import * as styles from "./index.module.css";

export const HomeTips = ({ tips, className }) => {
  return (
    <div className={`${className} ${styles.tips}`}>
      <Swiper
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {tips.map((tip, index) => (
          <SwiperSlide key={index}>
            <div className={styles.tip}>
              <div className={styles.tip__imageContainer}>
                <img src={tip.imgSrc} alt="tip photo" />
              </div>
              <h2 className={styles.tip__title}>{tip.title}</h2>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
