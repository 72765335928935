// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__skeletonBox__K0MnW {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 400% 100%;
  animation: index-module__shimmer__KHDzQ 3.5s infinite linear;
}

@keyframes index-module__shimmer__KHDzQ {
  0% {
    background-position: -400% 0;
  }
  100% {
    background-position: 400% 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/Skeleton/index.module.css"],"names":[],"mappings":"AAAA;EACE,yEAAyE;EACzE,0BAA0B;EAC1B,4DAAuC;AACzC;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".skeletonBox {\n  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);\n  background-size: 400% 100%;\n  animation: shimmer 3.5s infinite linear;\n}\n\n@keyframes shimmer {\n  0% {\n    background-position: -400% 0;\n  }\n  100% {\n    background-position: 400% 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var skeletonBox = `index-module__skeletonBox__K0MnW`;
export var shimmer = `index-module__shimmer__KHDzQ`;
export default ___CSS_LOADER_EXPORT___;
