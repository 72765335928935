// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Ubuntu", serif;
  font-weight: 400;
  font-style: normal;
  background-color: #F3F3F3;
  overflow: hidden;
}

main.style-module__main__jfTuN {
  position: relative;
  width: 100%;
  height: calc(100dvh - 60px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-module__content__M1uL1 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

.style-module__content__M1uL1::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  p {
    font-size: 18px;
  }

  main.style-module__main__jfTuN {
    left: 80px;
    bottom: 0;
    height: 100dvh;
    width: calc(100% - 80px);
  }
}

@media (min-width: 1024px) {
  main.style-module__main__jfTuN {
    left: 0px;
    top: 80px;
    height: calc(100dvh - 80px);
    width: 100%;
  }
}

.swiper {
  align-items: stretch;
}

.swiper-slide {
  display: flex;
  height: auto;
}

`, "",{"version":3,"sources":["webpack://./src/app/styles/style.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,2BAA2B;EAC3B,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,wBAAwB;EACxB,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,SAAS;IACT,cAAc;IACd,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;EACb;AACF;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":["body {\n  font-family: \"Ubuntu\", serif;\n  font-weight: 400;\n  font-style: normal;\n  background-color: #F3F3F3;\n  overflow: hidden;\n}\n\nmain.main {\n  position: relative;\n  width: 100%;\n  height: calc(100dvh - 60px);\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  scroll-behavior: smooth;\n  overscroll-behavior: contain;\n}\n\n.content::-webkit-scrollbar {\n  display: none;\n}\n\n@media (min-width: 768px) {\n  p {\n    font-size: 18px;\n  }\n\n  main.main {\n    left: 80px;\n    bottom: 0;\n    height: 100dvh;\n    width: calc(100% - 80px);\n  }\n}\n\n@media (min-width: 1024px) {\n  main.main {\n    left: 0px;\n    top: 80px;\n    height: calc(100dvh - 80px);\n    width: 100%;\n  }\n}\n\n:global(.swiper) {\n  align-items: stretch;\n}\n\n:global(.swiper-slide) {\n  display: flex;\n  height: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var main = `style-module__main__jfTuN`;
export var content = `style-module__content__M1uL1`;
export default ___CSS_LOADER_EXPORT___;
