import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLocation, setLoading, setError } from "./locationSlice";

export const fetchUserLocation = createAsyncThunk(
  "location/fetchUserLocation",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));

    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        dispatch(setError("Geolocation not supported"));
        dispatch(setLoading(false));
        return reject("Geolocation not supported");
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            setLocation({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            })
          );
          dispatch(setLoading(false));
          resolve();
        },
        (error) => {
          dispatch(setError(error.message));
          dispatch(setLoading(false));
          reject(error);
        }
      );
    });
  }
);
