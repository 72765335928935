import React, { Component } from "react";
import * as styles from "./index.module.css"

export const Container = ({ className, children }) => {
  return (
    <div className={`${className} ${styles.container}`}>
      {children}
    </div>
  );
};
